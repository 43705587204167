<template>
	<el-container>
		<y-header></y-header>
		<el-main>
			<div class="page-container">
				<!-- <div class="qrcode-float" :style="'top:' + (activeStep == 2 ? '3' : '26.7') + 'rem'">
					<img src="https://image.homeplus.cn/cms/edcdcbcd08b34f108c491a70af3c09c2.webp" />
					<p>碰到问题了？微信 扫一扫联系我们</p>
				</div> -->
				<div class="step-top-cont">
					<div class="top-tips">基础配置中第1、2、3步为必需</div>
					<el-steps :active="activeStep" class="fullfill f12" align-center>
						<el-step title="录入商户信息" @click="stepTo(0)"></el-step>
						<el-step title="基础服务开通" @click="stepTo(1)"></el-step>
						<el-step title="组织架构设置" @click="stepTo(2)"></el-step>
						<el-step title="配置完成" @click="stepTo(3)"></el-step>
					</el-steps>
				</div>
				<div class="step-cont" v-if="activeStep == 0">
					<basic-info class="form-container" :form="form0" :disabled="form0.isOk > 0" submit-text="下一步" @submit="stepTo(1, $event)" />
				</div>
				<div class="form-container step-cont" v-if="activeStep == 1">
					<service-info
						:form="form1"
						:disabled="form1.isOpenAccount == 1 || form1.isOk > 1"
						cancel-text="上一步"
						submit-text="下一步"
						@cancel="prevTo(0)"
						@submit="stepTo(2, $event)"
					/>
				</div>
				<div class="" v-if="activeStep == 2">
					<org-info :form="form2" cancel-text="上一步" submit-text="下一步" @cancel="prevTo(1)" @submit="stepTo(3)" />
				</div>
				<div class="" v-if="activeStep == 3">
					<success-info :user="user" />
				</div>
			</div>
		</el-main>
		<y-footer></y-footer>
	</el-container>
</template>
<script>
import { mapState } from "vuex"
import BasicInfo from "@/components/forms/BasicInfo"
import ServiceInfo from "@/components/forms/ServiceInfo"
import OrgInfo from "@/components/forms/OrgInfo"
import SuccessInfo from "@/components/forms/SuccessInfo"

export default {
	computed: { ...mapState(["FVs", "user", "login"]) },
	data() {
		return {
			avaliableStep: 3,
			activeStep: 0,
			form0: {},
			form1: {},
			form2: {},
			formFlag: false,
			id: null
		}
	},
	created() {
		if (this.$query.step) {
			this.activeStep = parseInt(this.$query.step)
		}
		if (this.activeStep == 0) {
			this.getFirstDetail()
		} else if (this.activeStep == 1) {
			this.getSecondDetail()
		}
	},
	mounted() {},
	methods: {
		// 获取用户之前进行到流程的哪一步
		getProcessDetail() {},
		getFirstDetail() {
			this.$Xhr({
				url: "/ptBusinessEnterpriseInfo/detail",
				data: {},
				method: "post"
			}).then(res => {
				this.form0 = res
				this.form0.householdAddress = res.provinceName + res.cityName
				this.id = res.id
			})
		},
		getSecondDetail() {
			this.$Xhr({
				url: "/ptBusinessEnterpriseInfo/secondStepSeDtail",
				data: {},
				method: "post"
			}).then(res => {
				this.form1 = res
				this.form1.householdAddress = res.bankProvinceName + res.bankCityName
			})
		},
		stepTo(step, e) {
			if (step > this.avaliableStep) {
				this.$toast("暂时无法跳转到该步骤噢，请按顺序填写", "error")
				return false
			}
			if (step == 1) {
				if (!this.form0.isOk) {
					this.$confirm("请再次确认企业基本信息填写是否正确，确认之后将生成商户信息。", "提示", {
						confirmButtonText: "确认",
						cancelButtonText: "取消"
					}).then(() => {
						this.form0 = e
						this.saveForm0()
					})
				} else {
					this.$router.replace({ name: "initialSetting", query: { step: 1 } })
				}
			} else if (step == 2) {
				this.form1 = e
				this.saveForm1()
			} else if (step == 3) {
				let { id } = this
				if (id) {
					this.saveForm2()
				} else {
					this.$Xhr({
						url: "/ptBusinessEnterpriseInfo/detail",
						data: {},
						method: "post"
					}).then(res => {
						this.id = res.id
						this.saveForm2()
					})
				}
			}
		},
		prevTo(steps) {
			this.$router.replace({ name: "initialSetting", query: { step: steps } } || -1)
		},
		saveForm0() {
			this.formFlag = true
			this.$Xhr({
				url: "/ptBusinessEnterpriseInfo/saveOrUpdate",
				data: this.form0,
				method: "post"
			})
				.then(() => {
					this.formFlag = false
					this.$toast("保存成功")
					this.$router.replace({ name: "initialSetting", query: { step: 1 } })
				})
				.catch(() => {
					this.formFlag = false
				})
		},
		saveForm1() {
			this.$router.replace({ name: "initialSetting", query: { step: 2 } })
		},
		saveForm2() {
			this.formFlag = true
			this.$Xhr({
				url: "/ptBusinessEnterpriseInfo/saveThreeStep",
				data: { id: this.id },
				method: "post"
			})
				.then(() => {
					this.formFlag = false
					this.$toast("保存成功")
					this.$store.dispatch("refreshUser")
					this.$router.replace({ name: "initialSetting", query: { step: 3 } })
				})
				.catch(() => {
					this.formFlag = false
				})
		}
	},
	components: {
		BasicInfo,
		ServiceInfo,
		OrgInfo,
		SuccessInfo
	}
}
</script>
<style scoped lang="scss">
.step-top-cont {
	width: 74.6rem;
	margin: 0 auto 2.7rem;
	@media screen and (max-width: 1024px) {
		padding: 0 1.5rem 2rem;
		padding-top: 3rem;
		margin-bottom: 2rem;
		width: 100%;
		background: #fbfbfb;
	}
}
.top-tips {
	color: #a56b01;
	background: #fff8d8;
	height: 4rem;
	line-height: 4rem;
	border-radius: 0.4rem;
	text-align: center;
	font-size: 1.2rem;
	margin-bottom: 2.6rem;
	@media screen and (max-width: 1024px) {
		display: none;
	}
}
.step-cont {
	width: 63.1rem;
	margin: 0 auto;
	@media screen and (max-width: 1024px) {
		width: 100%;
		background: #fff;
	}
}
.qrcode-float {
	position: absolute;
	top: 26.7rem;
	right: 2rem;
	width: 13.8rem;
	height: 17rem;
	background: #ffffff;
	box-shadow: 0px 4px 10px 0px rgba(131, 151, 192, 0.1);
	border: 0.1rem solid #efefef;
	@media screen and (max-width: 1024px) {
		display: none;
	}
}
.qrcode-float img {
	width: 10rem;
	height: 10rem;
	margin: 1.6rem 1.9rem 0.5rem;
}
.qrcode-float p {
	width: 10rem;
	margin: 0 auto;
	line-height: 1.6rem;
	font-size: 1.2rem;
}
</style>

<template>
	<div>
		<el-upload :disabled="disabled" class="avatar-uploader" action="" :show-file-list="false" accept=".jpeg,.jpg,.png" :on-success="success" :http-request="upload">
			<div class="avatar-uploaded-cont" v-if="imageUrl">
				<img :src="imageUrl" class="avatar-img" />
				<span class="el-upload-list__item-actions" @click.stop>
					<span class="el-upload-list__item-preview" @click="previewImg()">
						<i class="el-icon-zoom-in"></i>
					</span>
					<span v-if="!disabled" class="el-upload-list__item-delete" @click="delImg()">
						<i class="el-icon-delete"></i>
					</span>
				</span>
			</div>
			<slot v-else />
			<!--这个slot用来传默认图样式，已上传的图用file-list这个props来传值-->
		</el-upload>
		<el-dialog :visible.sync="previewModalShow">
			<img width="100%" :src="imageUrl" alt="" />
		</el-dialog>
	</div>
</template>
<script>
import UUID from "@/lib/uuid"
export default {
	name: "upload-pic",
	data: function() {
		return {
			fulled: false,
			previewModalShow: false
		}
	},
	props: {
		disabled: {
			default: false
		},
		imageUrl: {
			type: String
		}
	},
	watch: {
		imageUrl(val) {
			// console.log("imageUrl: ", val)
		}
	},
	methods: {
		previewImg() {
			this.previewModalShow = true
		},
		success(res) {
			this.$emit("change", res)
		},
		delImg() {
			this.$emit("change", null)
		},
		upload(e) {
			// console.log(e.file)
			this.$Xhr({
				url: "/aliyun/get?dir=image",
				method: "get"
			})
				.then(config => {
					var subfixIndex = e.file.name.lastIndexOf(".")
					var subfix = subfixIndex > -1 ? e.file.name.slice(subfixIndex + 1) : ""
					subfix && (subfix = subfix.toLowerCase())
					var uuid = new UUID().toString()
					var key
					if (subfix) {
						key = config.dir + uuid + "." + subfix
					} else {
						key = config.dir + uuid
					}
					var fd = new FormData()
					fd.append("key", key)
					fd.append("x-oss-meta-fullname", e.file.name)
					fd.append("success_action_status", "200")
					fd.append("x-oss-object-acl", "public-read")
					fd.append("OSSAccessKeyId", config.accessId)
					fd.append("policy", config.policy)
					fd.append("signature", config.signature)
					fd.append("Content-disposition", `attachment;filename=${e.file.name}`)
					fd.append("file", e.file)
					if (config.host.indexOf("http:") > -1) {
						var protocol = window.location.protocol || "http:"
						var subUrl = config.host.substring(5, config.host.length)
						config.host = protocol + subUrl
					}
					this.$Xhr({
						url: config.host,
						method: "post",
						isUpload: true,
						data: fd,
						headers: { "Content-Type": "multipart/form-data" }
					}).then(() => {
						let url = config.host + "/" + key
						let res = { aliyunKey: key, name: e.file.name, url }
						e.onSuccess(res)
					})
				})
				.catch(() => {
					this.delImg()
					this.$toast("获取签名失败")
				})
		}
	},
	created: function() {}
}
</script>
<style scoped></style>

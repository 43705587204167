<template>
	<el-form ref="data" :rules="rules" :model="data" :label-width="$isMobile ? '9rem' : '14.9rem'">
		<div class="form-item">
			<el-checkbox class="asterisk bold" v-model="checked" disabled required>开通短信服务通知</el-checkbox>
			<span class="form-desc">（用于悠租云平台向租客发送短信）</span>
		</div>
		<div class="spot-line"></div>
		<div class="form-item">
			<el-checkbox class="asterisk bold" v-model="checked" disabled>开通企查查服务</el-checkbox>
			<span class="form-desc">（用于企业客户签约时获取企业信息） </span>
		</div>
		<div class="spot-line"></div>
		<div class="form-item">
			<el-checkbox class="asterisk bold" v-model="checked" disabled>开通实名认证服务</el-checkbox>
			<span class="form-desc">（用于对个人租客进行实名认证） </span>
		</div>
		<div class="spot-line"></div>
		<div class="form-item">
			<el-checkbox class="asterisk bold" v-model="checked" disabled>开通e签宝电子签名服务</el-checkbox>
			<span class="form-desc">（用于在线电子签约） </span>
		</div>
		<div class="form-item-sub">
			<div class="mg-b20">
				<a href="http://homeplus.oss-cn-hangzhou.aliyuncs.com/homeplus/C96059DB-1F30-0001-EC77-4AA01B60AD80">下载授权书和印章采集模板</a
				><span class="form-desc">（请下载模板打印后盖章，然后将扫描件上传）</span>
			</div>
			<div class="upload-cont mg-b20">
				<span class="upload-cont-label">授权书及印章采集 </span>
				<div class="button">
					<el-upload
						class="upload-button"
						:class="{ 'upload-button-full': fileList && fileList.length }"
						action=""
						:http-request="upload"
						:on-remove="delFile"
						:on-preview="downloadFile"
						:limit="1"
						:file-list="fileList"
						:disabled="disabled"
						accept=".jpeg,.jpg,.png,.pdf"
					>
						<el-button type="cancel" class="upload-auth-btn" v-if="fileList.length == 0">+上传授权书和印章采集扫描件</el-button>
					</el-upload>
					<div slot="tip" class="form-desc el-upload__tip">支持pdf,jpg,png,jpeg类型，且不超过500kb</div>
				</div>
			</div>
		</div>
		<div class="spot-line"></div>
		<div class="form-item">
			<el-checkbox class="asterisk bold" v-model="checked" disabled>开通在线收款</el-checkbox>
			<span class="form-desc">（在线收款由通联支付和平安银行提供服务）</span>
		</div>
		<div class="form-item-sub">
			<el-form-item label="请选择服务商" prop="openBankType">
				<el-radio-group v-model="data.openBankType" :disabled="disabled">
					<el-radio label="01">通联支付</el-radio>
					<el-radio label="02">平安银行</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="开户银行" prop="legalBank">
				<div class="dis-f">
					<el-autocomplete
						value-key="bankName"
						placeholder="请选择开户银行"
						v-model="data.legalBankName"
						:fetch-suggestions="autoCompleteBank"
						@select="selectBank"
						hide-loading
						suffix-icon="el-icon-search"
						class="mg-r10"
						:disabled="disabled"
					></el-autocomplete>
					<el-form-item style="padding-right: 0">
						<city-select
							:customUrls="['/ptBusinessEnterpriseInfo/queryPageNodeList', '/ptBusinessEnterpriseInfo/queryPageCityList']"
							:codes="['nodeCode', 'oraAreaCode']"
							:names="['nodeName', 'areaName']"
							:ids="['nodeCode', 'areaCode']"
							extraClass="city-select"
							:value="data.householdAddress"
							placeholder="请选择省市"
							@selected="selectCity"
							:disabled="disabled"
						/>
					</el-form-item>
				</div>
			</el-form-item>
			<el-form-item label="开户支行" prop="receiptBranchCode">
				<el-autocomplete
					value-key="lname"
					:placeholder="!data.legalBank ? '请先选择开户银行' : !data.bankCityId ? '请先选择支行所在省市' : '请选择开户支行'"
					v-model="data.receiptBranch"
					:fetch-suggestions="autoCompleteBranch"
					@select="selectBranch"
					suffix-icon="el-icon-search"
					:disabled="data.isOpenAccount == 1 || !data.bankCityId || !data.legalBank ? true : false"
				></el-autocomplete>
			</el-form-item>
			<el-form-item label="账户名称" prop="accounter">
				<el-input v-model="data.accounter" maxlength="50" placeholder="请输入账户名称" :disabled="disabled"></el-input>
			</el-form-item>
			<el-form-item label="账号" prop="legalBankCode">
				<el-input v-model="data.legalBankCode" maxlength="20" placeholder="请输入银行账号" :disabled="disabled"></el-input>
			</el-form-item>
			<el-form-item label="开户资料" :class="{ pd0: $isMobile }">
				<div class="pics-cont">
					<div class="fc-c img-item">
						<div class="account-img"><img :src="this.imgPrefix(data.businessLicenseUrl, this.ossPrefix)" /></div>
						<span>营业执照</span>
					</div>
					<div class="fc-c img-item">
						<div class="account-img"><img :src="this.imgPrefix(data.idCardFrontUrl, this.ossPrefix)" /></div>
						<span>法人身份证正面</span>
					</div>
					<div class="fc-c img-item">
						<div class="account-img"><img :src="this.imgPrefix(data.idCardReverseUrl, this.ossPrefix)" /></div>
						<span>法人身份证反面</span>
					</div>
					<div v-if="data.openPermitUrl" class="fc-c img-item">
						<div class="account-img"><img :src="this.imgPrefix(data.openPermitUrl, this.ossPrefix)" /></div>
						<span>开户许可证</span>
					</div>
				</div>
			</el-form-item>
			<el-form-item label="提现人姓名" prop="extractName">
				<el-input v-model="data.extractName" maxlength="20" placeholder="请输入提现人姓名" :disabled="disabled"></el-input>
			</el-form-item>
			<verify
				v-if="data.isOpenAccount != 1"
				ref="ver"
				:config="verifyConfig"
				:mobile="data.extractNum"
				:url="authCodeUrl"
				:method="'post'"
				:labelWidth="$isMobile ? '9rem' : '14.9rem'"
				@change="syncCode"
			></verify>
		</div>
		<!-- <div class="agree-txt">
			<el-checkbox class="agree-text" v-model="agree" :disabled="disabled">我已阅读、理解并同意 </el-checkbox> <a class="v-m">《在线支付协议》</a>
		</div> -->
		<div class="form-foot-buttons" :style="{ marginLeft: $isMobile ? '0' : '14.9rem' }">
			<el-button type="cancel" @click="cancel">{{ cancelText }}</el-button>
			<el-button type="primary" @click="submit">{{ submitText }}</el-button>
		</div>

		<el-dialog title="开户确认" :visible.sync="modalShow" width="50rem">
			<span v-if="data.openBankType == '01'">您提交的开户信息已发送给通联支付，开户结果后续请在运营平台中查看，当前您可以继续配置企业组织信息。</span>
			<span v-if="data.openBankType == '02'">平台将向该帐号自动转账0.01～1元，请在24小时内登录企业网银查看，并将收到的金额填入到下方的输入框中。</span>
			<div v-if="data.openBankType == '02'" class="confirm">
				<span class="confirm-label">金额：</span><el-input v-model="sum" class="c-input"></el-input><span style="width: 2rem; text-align: end;">元</span>
			</div>
			<div v-if="data.openBankType == '02'" class="confirm"><span class="confirm-label">验证码：</span><el-input v-model="code" class="c-input"></el-input></div>
			<span slot="footer" class="dialog-footer">
				<el-button v-if="data.openBankType == '01'" @click="confirmBind">确 定</el-button>
				<el-button v-if="data.openBankType == '02'" @click="modalShow = false">取 消</el-button>
				<el-button v-if="data.openBankType == '02'" type="primary" @click="verifyBindCard">确 定</el-button>
			</span>
		</el-dialog>
	</el-form>
</template>
<script>
import { mapState } from "vuex"
import UUID from "@/lib/uuid"
import Verify from "@/components/Verify"
import CitySelect from "@/components/CitySelect"
import { download } from "@/lib/tools"

export default {
	name: "service-info",
	computed: { ...mapState(["ossPrefix"]) },
	data: function() {
		return {
			submitFlag: false,
			checked: true,
			modalShow: false,
			data: {
				openBankType: "",
				legalBank: null,
				legalBankName: "",
				receiptBranch: "",
				receiptBranchCode: "",
				accounter: "",
				legalBankCode: "",
				extractName: "",
				businessLicenseUrl: "",
				idCardFrontUrl: "",
				idCardReverseUrl: "",
				openPermitUrl: "",
				bankprovinceName: null,
				bankprovinceId: null,
				bankCityName: null,
				bankCityId: null,
				signPermitUrl: "",
				signPermitName: ""
			},
			fileList: [],
			agree: false,
			verifyConfig: {
				mobile: { label: "提现人手机", prop: "mobile" },
				authCode: { label: "短信验证码", prop: "authCode" }
			},
			rules: {
				openBankType: [{ required: true, message: "请选择服务商", trigger: ["change"] }],
				legalBank: [{ required: true, message: "请选择开户银行", trigger: ["change"] }],
				receiptBranchCode: [{ required: true, message: "请选择开户支行", trigger: ["change"] }],
				accounter: [{ required: true, message: "请输入账户名称", trigger: ["blur", "change"] }],
				legalBankCode: [{ required: true, message: "请输入账号", trigger: ["blur", "change"] }],
				extractName: [{ required: true, message: "请输入提现人名称", trigger: ["blur", "change"] }],
				extractNum: [{ required: true, message: "请输入提现人手机", trigger: ["blur", "change"] }],
				verificationCode: [{ required: true, message: "请输入短信验证码", trigger: ["blur", "change"] }]
			},
			authCodeUrl: "/ptBusinessEnterpriseInfo/getAuthCode",
			sum: "",
			code: ""
		}
	},
	props: {
		form: { default: {} },
		disabled: { default: false },
		cancelText: { default: "取消" },
		submitText: { default: "提交" },
		size: { default: null },
		classNames: {}
	},
	watch: {
		form(val) {
			this.data = Object.assign(this.data, val)
			this.data.signPermitUrl && (this.fileList = [{ aliyunKey: this.data.signPermitUrl, name: this.data.signPermitName }])
		}
	},
	methods: {
		syncCode(mobileInfo) {
			this.data.extractNum = mobileInfo.mobile
			this.data.verificationCode = mobileInfo.authCode
		},
		selectCity({ nodes }) {
			// console.log(nodes)
			if (nodes[1] && nodes[1].label && this.data.bankCityId && this.data.bankCityId != nodes[1].oraAreaCode) {
				this.data.receiptBranch = ""
				this.data.receiptBranchCode = ""
			}
			this.data.bankProvinceId = nodes[0].value || ""
			this.data.bankProvinceName = nodes[0].label || ""
			this.data.bankCityId = (nodes[1] && nodes[1].oraAreaCode) || ""
			this.data.bankCityName = (nodes[1] && nodes[1].label) || ""
		},
		autoCompleteBank(queryString, cb) {
			this.$Xhr({
				url: "/bankCode/searchBankCode",
				data: { key: queryString },
				method: "post",
				noLoading: true
			}).then(res => {
				cb(res.rows)
			})
		},
		selectBank(item) {
			if (this.data.legalBank && this.data.legalBank != item.bankCode) {
				this.data.receiptBranch = ""
				this.data.receiptBranchCode = ""
			}
			this.data.legalBank = item.bankCode
		},
		autoCompleteBranch(queryString, cb) {
			this.$Xhr({
				url: "/ptBusinessEnterpriseInfo/queryBankaPageList",
				data: {
					bnkcode: queryString,
					clscode: this.data.legalBank,
					citycode: this.data.bankCityId
				},
				method: "post"
			}).then(res => {
				cb(res.rows)
			})
		},
		selectBranch(item) {
			this.data.receiptBranch = item.lname
			this.data.receiptBranchCode = item.bnkcode
		},
		cancel() {
			this.$emit("cancel", this.data)
		},
		submit() {
			if (this.data.isOpenAccount != 1) {
				if (!this.data.signPermitUrl) {
					this.$toast("请上传授权书和印章采集扫码件", "error")
					return false
				}
				this.$refs.data
					.validate()
					.then(() => {
						this.$refs.ver.validate().then(() => {
							this.submitFlag = true
							this.$Xhr({
								url: "/ptBusinessEnterpriseInfo/saveSecondStep",
								data: this.data,
								method: "post"
							})
								.then(() => {
									this.submitFlag = false
									this.modalShow = true
								})
								.catch(() => {
									this.submitFlag = false
								})
						})
					})
					.catch(() => {
						this.$refs.ver.validate()
					})
			} else {
				this.$emit("submit", this.data)
			}
		},
		delFile() {
			setTimeout(() => {
				this.fileList = []
				this.data.signPermitUrl = ""
				this.data.signPermitName = ""
			}, 350)
		},
		downloadFile() {
			// window.open(this.imgPrefix(this.fileList[0].aliyunKey, this.ossPrefix))
			download(this.fileList[0].aliyunKey)
		},
		upload(e) {
			this.$Xhr({
				url: "/aliyun/get?dir=image",
				method: "get"
			})
				.then(config => {
					var uuid = new UUID().toString()
					let key = config.dir + uuid
					var fd = new FormData()
					fd.append("key", key)
					fd.append("success_action_status", "200")
					fd.append("x-oss-object-acl", "public-read")
					fd.append("OSSAccessKeyId", config.accessId)
					fd.append("policy", config.policy)
					fd.append("signature", config.signature)
					fd.append("x-oss-meta-fullname", e.file.name)
					fd.append("Content-disposition", `attachment;filename=${e.file.name}`)
					fd.append("file", e.file)
					if (config.host.indexOf("http:") > -1) {
						var protocol = window.location.protocol || "http:"
						var subUrl = config.host.substring(5, config.host.length)
						config.host = protocol + subUrl
					}
					this.$Xhr({
						url: config.host,
						method: "post",
						isUpload: true,
						data: fd,
						headers: { "Content-Type": "multipart/form-data" }
					}).then(() => {
						e.file.aliyunUrl = config.host + "/" + key
						setTimeout(() => {
							this.fileList = [{ aliyunKey: key, name: e.file.name }]
							this.data.signPermitUrl = key
							this.data.signPermitName = e.file.name
						}, 350)
					})
				})
				.catch(() => {
					this.delImg()
					this.$toast("获取签名失败")
				})
		},
		verifyBindCard() {
			let { id } = this.data
			let authInfo = `${this.code};${this.sum}`
			this.$Xhr({
				url: "/ptBusinessEnterpriseInfo/verifyBindCardByChannel",
				data: { merchantId: id, authInfo },
				method: "post"
			}).then(() => {
				this.$toast("保存成功")
				this.$emit("submit", this.data)
			})
		},
		confirmBind() {
			this.$toast("保存成功")
			this.$emit("submit", this.data)
		}
	},
	created: function() {},
	components: {
		Verify,
		CitySelect
	}
}
</script>
<style scoped lang="scss">
.form-item {
	padding: 1.6rem 0;
	font-weight: bold;
	color: #333;
	@media screen and (max-width: 1024px) {
		padding: 1.6rem 2rem;
	}
}
.form-desc {
	color: #ccc;
	font-weight: 500;
	margin-left: 0.6rem;
	vertical-align: middle;
	@media screen and (max-width: 1024px) {
		font-size: 1.2rem;
		vertical-align: bottom;
	}
}
.spot-line {
	margin-left: 2.3rem;
	border-bottom: 0.1rem dashed #dfdfdf;
}
.form-item-sub {
	margin-left: 2.3rem;
	padding-right: 4rem;
	@media screen and (max-width: 1024px) {
		margin-left: 2rem;
		padding-right: 0;
		margin-right: 1.5rem;
		.el-form-item {
			padding: 0 2rem 0 0;
		}
	}
}
.upload-cont {
	display: flex;
}
.upload-cont-label {
	width: 13rem;
}
.upload-button {
	display: flex;
}
.upload-auth-btn {
	width: 20.6rem;
	font-size: 1.2rem;
}
.img-item {
	color: #666666;
	margin-right: 1rem;
	font-size: 1.2rem;
}
.account-img {
	width: 12.4rem;
	height: 8.1rem;
	border-radius: 4px;
	border: 1px solid #e5e5e5;
}
.agree-txt {
	margin-left: 14.9rem;
	padding-bottom: 2rem;
	@media screen and (max-width: 1024px) {
		width: 100%;
		text-align: center;
		margin-left: 0;
	}
}
.pics-cont {
	display: flex;
	@media screen and (max-width: 1024px) {
		flex-wrap: wrap;
		justify-content: space-between;
		.account-img {
			width: 11rem;
		}
	}
}
.account-img img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.confirm {
	display: flex;
	align-items: center;
	margin-top: 1rem;
}
.confirm-label {
	width: 6rem;
	text-align: end;
}
.c-input {
	width: 10rem;
}
</style>

import XEUtils from "xe-utils/ctor"

const browse = XEUtils.browse()
const htmlElem = browse.isDoc ? document.querySelector("html") : 0
const bodyElem = browse.isDoc ? document.body : 0
const reClsMap = {}

function getClsRE(cls) {
	if (!reClsMap[cls]) {
		reClsMap[cls] = new RegExp(`(?:^|\\s)${cls}(?!\\S)`, "g")
	}
	return reClsMap[cls]
}

function getNodeOffset(elem, container, rest) {
	if (elem) {
		const parentElem = elem.parentNode
		rest.top += elem.offsetTop
		rest.left += elem.offsetLeft
		if (parentElem && parentElem !== htmlElem && parentElem !== bodyElem) {
			rest.top -= parentElem.scrollTop
			rest.left -= parentElem.scrollLeft
		}
		if (container && (elem === container || elem.offsetParent === container) ? 0 : elem.offsetParent) {
			return getNodeOffset(elem.offsetParent, container, rest)
		}
	}
	return rest
}

export const DomTools = {
	browse,
	isPx(val) {
		return val && /^\d+(px)?$/.test(val)
	},
	isScale(val) {
		return val && /^\d+%$/.test(val)
	},
	hasClass(elem, cls) {
		return elem && elem.className && elem.className.match && elem.className.match(getClsRE(cls))
	},
	removeClass(elem, cls) {
		if (elem && DomTools.hasClass(elem, cls)) {
			elem.className = elem.className.replace(getClsRE(cls), "")
		}
	},
	addClass(elem, cls) {
		if (elem && !DomTools.hasClass(elem, cls)) {
			DomTools.removeClass(elem, cls)
			elem.className = `${elem.className} ${cls}`
		}
	},

	getDomNode() {
		const documentElement = document.documentElement
		const bodyElem = document.body
		return {
			scrollTop: documentElement.scrollTop || bodyElem.scrollTop,
			scrollLeft: documentElement.scrollLeft || bodyElem.scrollLeft,
			visibleHeight: documentElement.clientHeight || bodyElem.clientHeight,
			visibleWidth: documentElement.clientWidth || bodyElem.clientWidth
		}
	},
	/**
	 * 检查触发源是否属于目标节点
	 */
	getEventTargetNode(evnt, container, queryCls, queryMethod) {
		let targetElem
		let target = evnt.target
		while (target && target.nodeType && target !== document) {
			if (queryCls && DomTools.hasClass(target, queryCls) && (!queryMethod || queryMethod(target))) {
				targetElem = target
			} else if (target === container) {
				return { flag: queryCls ? !!targetElem : true, container, targetElem: targetElem }
			}
			target = target.parentNode
		}
		return { flag: false }
	},
	/**
	 * 获取元素相对于 document 的位置
	 */
	getOffsetPos(elem, container) {
		return getNodeOffset(elem, container, { left: 0, top: 0 })
	},
	getAbsolutePos(elem) {
		const bounding = elem.getBoundingClientRect()
		const boundingTop = bounding.top
		const boundingLeft = bounding.left
		const { scrollTop, scrollLeft, visibleHeight, visibleWidth } = DomTools.getDomNode()
		return { boundingTop, top: scrollTop + boundingTop, boundingLeft, left: scrollLeft + boundingLeft, visibleHeight, visibleWidth }
	},

	triggerEvent(targetElem, type) {
		let evnt
		if (typeof Event === "function") {
			evnt = new Event(type)
		} else {
			evnt = document.createEvent("Event")
			evnt.initEvent(type, true, true)
		}
		targetElem.dispatchEvent(evnt)
	},
	calcHeight($xetable, key) {
		const val = $xetable[key]
		let num = 0
		if (val) {
			if (val === "auto") {
				num = $xetable.parentHeight
			} else {
				if (DomTools.isScale(val)) {
					num = Math.floor((parseInt(val) / 100) * $xetable.parentHeight)
				} else {
					num = XEUtils.toNumber(val)
				}
				num -= $xetable.getExcludeHeight()
			}
		}
		return num
	}
}

export default DomTools

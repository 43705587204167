<template>
	<div class="index-cont">
		<img :src="$staticUrl + 'images/ico-success.png'" class="success-icon" />
		<div>
			<div class="success-text">
				<div>您已完成账户开通和基础设置，您开通的产品如下：</div>
				<div>一、基础产品</div>
				<div>1，商户基础信息</div>
				<div>企业名称：{{ detail.enterprisePerson }}</div>
				<div>统一信用代码：{{ detail.creditCode }}</div>
				<div>联系人：{{ user.linkPerson }}</div>
				<div class="mg-b10">联系电话：{{ detail.enterprisePhone }}</div>
				<div>2，基础服务</div>
				<div>短信通知</div>
				<div>开通企查查查询企业信息</div>
				<div>个人实名认证</div>
				<div>在线电子签名</div>
				<div class="mg-b10">在线收款：{{ detail.openBankType == "01" ? "通联支付" : "平安银行" }}</div>
				<div>3，组织架构、用户、权限</div>
				<div class="mg-b10">系统管理员：{{ user.mobile }} {{ user.linkPerson }}</div>
				<div class="password">悠租云默认登录密码：123.com</div>
			</div>
			<div class="fr-c">
				<!-- <el-button type="cancel" @click="go({ name: 'initialSetting' })">返回修改配置</el-button> -->
				<el-button type="primary" @click="goPlatform">登录悠租云进行开店</el-button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "success-info",
	data: function() {
		return {
			detail: {}
		}
	},
	props: ["user"],
	methods: {
		getSecondDetail() {
			this.$Xhr({
				url: "/ptBusinessEnterpriseInfo/secondStepSeDtail",
				data: {},
				method: "post"
			}).then(res => {
				this.detail = res
			})
		},
		goPlatform() {
			this.$Xhr({
				url: "/ptBusinessEnterpriseInfo/getLoginPcUrl",
				data: {}
			}).then(res => {
				let self = this
				try {
					if (self.elemIF) {
						document.body.removeChild(self.elemIF)
					}
					var elemIF = document.createElement("iframe")
					elemIF.setAttribute("sandbox", "allow-forms allow-scripts allow-same-origin allow-popups")
					elemIF.src = res.logoutUrl
					elemIF.style.display = "none"
					self.elemIF = elemIF
					document.body.appendChild(elemIF)
					setTimeout(() => {
						location.href = res.url
					}, 500)
				} catch (e) {
					console.log("failed: clear")
				}
			})
		}
	},
	created: function() {
		this.getSecondDetail()
	}
}
</script>
<style lang="scss" scoped>
.el-main {
	display: flex;
	flex-direction: column;
}
.page-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
}
.index-cont {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 10rem;
}
.success-icon {
	width: 10.4rem;
	height: 10.4rem;
	margin-bottom: 2.8rem;
}
.success-text {
	color: #999;
	max-width: 46rem;
	margin: 1.2rem 0 5rem;
	@media screen and (max-width: 1024px) {
		max-width: 31rem;
	}
}
.password {
	color: #ff0000;
}
</style>

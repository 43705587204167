<template>
	<div :id="id" :class="extraClass" class="city-select-cont" @click.stop>
		<el-input class="w-p100" v-model="display" readonly :placeholder="placeholder" @focus="open" suffix-icon="el-icon-arrow-down" :disabled="disabled"></el-input>
		<div class="el-popper el-cascader__dropdown el-popper-hack" v-show="show" style="position: absolute; top:34px; left:0px; z-index: 2009;">
			<div class="el-cascader-panel">
				<div class="el-scrollbar el-cascader-menu" v-show="level <= active.length && arr.length" v-for="(arr, level) in displayOptions">
					<div class="el-cascader-menu__wrap el-scrollbar__wrap">
						<ul class="el-scrollbar__view el-cascader-menu__list">
							<li
								@click.stop="handleItemChange(item, level)"
								class="el-cascader-node"
								:class="{ 'in-active-path': active[level] && item.value == active[level].value }"
								v-for="item in arr"
							>
								<i class="el-icon-check el-cascader-node__prefix" v-show="item.leaf && active[level] && item.value == active[level].value"></i>
								<span class="el-cascader-node__label">{{ item.label }}</span>
								<i class="el-icon-arrow-right el-cascader-node__postfix" v-show="!item.leaf"></i>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Vue from "vue"
import DomTools from "@/lib/dom"
export default {
	name: "city-select",
	data: function() {
		return {
			show: false,
			displayOptions: [],
			options: [],
			result: [],
			active: [],
			urls: [],
			display: "",
			id: "",
			nameKeys: [],
			idKeys: [],
			codeKeys: []
		}
	},
	props: {
		value: {},
		type: {},
		placeholder: {},
		extraClass: {},
		deep: {},
		customUrls: {},
		names: {
			type: Array,
			default: function() {
				return []
			}
		},
		ids: {
			type: Array,
			default: function() {
				return []
			}
		},
		codes: {
			type: Array,
			default: function() {
				return []
			}
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	created() {
		this.id = "citySelect" + parseInt(Math.random() * 10000)
		this.display = this.value
		if (this.type == "province") {
			this.nameKeys = ["provincename", "cityname", "countyname"]
			this.idKeys = ["provinceid", "cityid", "countyid"]
			this.codeKeys = ["gbcode", "gbcode", "gbcode"]
			this.urls = [
				"/merchantsettle/province/api/queryAllProvinceList",
				"/merchantsettle/province/api/queryAllCityByProvinceIdNoFilter",
				"/forms/json/county/queryAllCountyByCityIdNoFilter"
			]
		} else if (this.type == "city") {
			this.nameKeys = ["cityname", "countyname", "regionname"]
			this.idKeys = ["cityid", "countyid", "regionid"]
			this.codeKeys = ["gbcode", "gbcode", "regioncoce"]
			this.urls = ["/forms/json/city/queryAllCityList", "/forms/json/county/queryAllCountyByCityId", "/forms/json/region/queryAllRegionByCountyId"]
		} else {
			this.nameKeys = this.names
			this.idKeys = this.ids
			this.codeKeys = this.codes
		}
		if (this.customUrls) {
			this.urls = this.customUrls
		}
		this.init()
	},
	watch: {
		value: function() {
			this.display = this.value || ""
		},
		customUrls: function(val) {
			if (val) {
				this.urls = val
			}
		},
		names: function(val) {
			this.nameKeys = val
		},
		ids: function(val) {
			this.idKeys = val
		},
		codes: function(val) {
			this.codeKeys = val
		}
	},
	mounted() {
		window.addEventListener("mousedown", event => {
			this.handleGlobalMousedownEvent(event)
		})
	},
	methods: {
		open: function() {
			this.show = true
		},
		close: function() {
			this.show = false
		},
		handleGlobalMousedownEvent(evnt) {
			const { $el, show } = this
			this.isActivated = DomTools.getEventTargetNode(evnt, $el).flag
			if (show && !this.isActivated) {
				this.close()
				this.$emit("close", { $event: evnt })
			}
		},
		init() {
			var self = this,
				nameKey = self.nameKeys[0],
				codeKey = self.codeKeys[0],
				idKey = self.idKeys[0]
			this.$Xhr({
				url: this.urls[0],
				method: "post",
				data: {},
				noLoading: true
			}).then(data => {
				var nodes = data.map(item => {
					let result = {
						value: item[idKey],
						label: item[nameKey],
						leaf: false,
						children: []
					}
					result[codeKey] = item[codeKey]
					return result
				})
				self.options = nodes
				self.options.forEach(function(item, index) {
					self.$set(self.options[index], "children", [])
				})
				Vue.set(self.displayOptions, 0, nodes)
			})
		},
		handleItemChange(selectItem, level) {
			var self = this,
				deep = this.deep || 2 // 默认2层
			if (level == 0) {
				self.active = [selectItem]
				self.displayOptions.splice(1, 2)
			} else if (level == 1) {
				Vue.set(self.active, level, selectItem)
				if (deep > 2 && self.active.length == 3) {
					// 3层的时候，选择2层需要同步更新子层
					self.active.splice(2, 1)
					self.displayOptions.splice(2, 1)
				}
			} else if (deep > 2) {
				Vue.set(self.active, level, selectItem)
			}
			if (selectItem.leaf) {
				self.select()
				return false
			}
			if (selectItem.children && selectItem.children.length) {
				Vue.set(self.displayOptions, level + 1, selectItem.children)
				return false
			} else if (selectItem.leaf) {
				self.select()
				return false
			}

			var url = self.urls[level + 1],
				prevNodesIdKey = self.idKeys[level],
				nameKey = self.nameKeys[level + 1],
				codeKey = self.codeKeys[level + 1],
				idKey = self.idKeys[level + 1],
				query = {}
			query[prevNodesIdKey] = selectItem.value

			this.$Xhr({
				url: url,
				method: "post",
				data: query,
				noLoading: true
			}).then(data => {
				var nodes = data.map(item => {
					let result = {
						value: item[idKey],
						label: item[nameKey],
						leaf: level + 1 >= deep - 1,
						children: level + 1 >= deep - 1 ? null : []
					}
					result[codeKey] = item[codeKey]
					return result
				})
				Vue.set(self.displayOptions, level + 1, nodes)
				for (var index = 0; index < self.options.length; index++) {
					var item = self.options[index]
					if (item.value == self.active[0].value) {
						if (level == 0) {
							if (!self.options[index].children.length) {
								self.options[index].children = nodes.length ? nodes : null
								if (nodes.length) {
									self.options[index].children.forEach(function(it, i) {
										self.$set(self.options[index].children[i], "children", [])
									})
								} else {
									self.options[index].leaf = true
									self.select()
								}
							}
						} else {
							for (var i = 0; i < item.children.length; i++) {
								var it = item.children[i]
								if (it.value == self.active[1].value) {
									if (nodes.length) {
										if (!self.options[index].children[i].children.length) {
											self.options[index].children[i].children = nodes.length ? nodes : null
										}
									} else {
										self.options[index].children[i].leaf = true
										self.select()
									}
									break
								}
							}
						}
						break
					}
				}
			})
		},
		select() {
			this.result = this.active.concat([])
			this.close()
			this.display = this.result.map(item => item.label).join("")
			// console.log(this.result)
			this.$emit("selected", { nodes: this.result, name: this.display })
		}
	}
}
</script>
<style scoped>
.city-select-cont {
	display: inline-block;
}
</style>

<template>
	<div>
		<div class="dis-f mg-t30">
			<div class="tree-cont">
				<p class="head">组织架构</p>
				<div class="tree">
					<el-input placeholder="输入关键字进行过滤" v-model="filterText"> </el-input>
					<el-tree class="filter-tree" :data="orgData" :props="defaultProps" default-expand-all :filter-node-method="filterNode" @node-click="handleNodeClick" ref="tree">
					</el-tree>
				</div>
			</div>
			<div class="grid-cont">
				<p class="head">员工及用户</p>
				<div class="grid">
					<el-form ref="form" :inline="true" class="search-form" :model="searchForm.data" label-width="5rem" @submit="search">
						<!-- <el-form-item label="工号">
							<el-input v-model="searchForm.data.username" placeholder="请输入工号"></el-input>
						</el-form-item> -->
						<el-form-item label="姓名">
							<el-input v-model="searchForm.data.truename" placeholder="请输入姓名"></el-input>
						</el-form-item>
						<el-form-item label="手机">
							<el-input v-model="searchForm.data.telephone" placeholder="请输入手机"></el-input>
						</el-form-item>
						<!-- <el-form-item label="角色">
							<el-input v-model="searchForm.data.roleNames" placeholder="请输入角色"></el-input>
						</el-form-item> -->
						<el-button type="primary" size="small" class="search-btn" @click="search">查询</el-button>
					</el-form>
					<vxe-grid
						class="search-grid"
						size="small"
						ref="grid"
						cell-class-name="c-grey"
						resizable
						auto-resize
						border="outer"
						align="center"
						:form-config="searchForm"
						:pager-config="{ pageSize: 10 }"
						:proxy-config="gridConfig.proxy"
					>
						<!-- <vxe-table-column field="username" width="20%" title="工号" show-overflow></vxe-table-column> -->
						<vxe-table-column field="truename" width="20%" title="姓名" show-overflow></vxe-table-column>
						<vxe-table-column field="telephone" width="20%" title="手机号码" show-overflow></vxe-table-column>
						<vxe-table-column field="deptName" width="20%" title="所属部门" show-overflow></vxe-table-column>
						<vxe-table-column field="username" width="20%" title="用户名" show-overflow></vxe-table-column>
						<vxe-table-column field="roleNames" width="20%" title="角色" show-overflow></vxe-table-column>
					</vxe-grid>
				</div>
			</div>
		</div>
		<div class="foot">
			<div class="f-1 fa-c">
				<a href="https://homeplus.oss-cn-hangzhou.aliyuncs.com/homeplus/C9943602-CC70-0001-8651-19B0115D1F62" class="f12 mg-r30">下载组织架构模板 </a>
				<el-upload
					ref="uploadOrg"
					class=" mg-r55"
					action=""
					:data="{ type: 'org' }"
					accept=".xlsx, .xls"
					:http-request="e => uploadExcel(e, 'org')"
					:limit="1"
					:show-file-list="false"
				>
					<el-button class="import-btn" type="cancel">导入组织架构</el-button>
				</el-upload>
				<a href="https://homeplus.oss-cn-hangzhou.aliyuncs.com/homeplus/C994360F-7B10-0001-25A5-117C10201B9A" class="f12 mg-r30">下载员工用户信息模板 </a>
				<el-upload ref="uploadUser" action="" accept=".xlsx, .xls" :http-request="e => uploadExcel(e, 'user')" :limit="1" :show-file-list="false">
					<el-button class="import-btn" type="cancel">导入员工用户</el-button>
				</el-upload>
			</div>
			<div>
				<el-button type="cancel" @click="cancel">{{ cancelText }}</el-button>
				<el-button type="primary" @click="submit">{{ submitText }}</el-button>
			</div>
		</div>
		<el-dialog title="提示" @close="closeModal" :close-on-click-modal="false" :visible.sync="modalShow" width="33rem" top="30vh" center>
			<div v-if="uploadStatus == 1">
				<span v-if="count < maxCount + 1">导入中，请稍候<span class="dot">...</span></span>
				<span v-else>导入超时，请重试</span>
			</div>
			<span v-if="uploadStatus == 2"
				>导入完成！<br />本次导入总数{{ totalNumber }}条，成功{{ sucNumber }}条，失败{{ failNumber }}条<br /><span v-if="resultfileAdd"
					>请<a :href="resultfileAdd">点击下载Excel</a>查看</span
				></span
			>
			<span v-if="uploadStatus == 9">导入失败！</span>
			<span slot="footer" class="dialog-footer">
				<el-button v-if="uploadStatus != 1 || !(count < maxCount + 1)" @click="closeModal">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { mapState } from "vuex"
import UUID from "@/lib/uuid"
export default {
	computed: {
		...mapState(["FVs", "user", "login", "ossPrefix"])
	},
	name: "org-info",
	watch: {
		filterText(val) {
			this.$refs.tree.filter(val)
		},
		FVs(val) {
			// console.log("FVs~~~~ ", val)
		}
	},
	data() {
		return {
			filterText: "",
			defaultProps: { children: "items", label: "orgName" },
			orgData: [],
			inited: false,
			timer: null,
			timeout: 5000, //请求时间间隔
			count: 1,
			maxCount: 36, //三分钟超时，即请求36次
			modalShow: false,
			uploadType: "",
			uploadKey: null,
			uploadId: null,
			uploadStatus: 1,
			totalNumber: null,
			sucNumber: null,
			failNumber: null,
			resultfileAdd: "",
			searchForm: {
				data: {
					orgType: ""
				}
			},
			gridConfig: {
				proxy: {
					props: {
						result: "rows",
						total: "total"
					},
					ajax: {
						query: ({ page }) => {
							let query = this.searchForm.data
							query.page = page.currentPage
							query.pageSize = page.pageSize
							return new Promise(res => {
								this.$Xhr({
									url: "/orgUser/queryUserList",
									data: query,
									method: "post",
									noLoading: true
								}).then(data => {
									res(data)
								})
							})
						}
					}
				}
			}
		}
	},
	props: {
		form: { default: {} },
		cancelText: { default: "取消" },
		submitText: { default: "提交" },
		size: { default: null },
		classNames: {}
	},
	methods: {
		filterNode(value, data) {
			if (!value) return true
			return data.orgName.indexOf(value) !== -1
		},
		handleNodeClick(data) {
			this.getUser(data)
		},
		getOrg() {
			this.$Xhr({
				url: "/orgUser/allSysOrg",
				data: {},
				method: "post"
			}).then(res => {
				this.orgData = [res]
				if (!this.inited) {
					this.inited = true
				} else {
					this.getUser(res)
				}
			})
		},
		getUser(data) {
			this.searchForm.data.orgType = data ? data.longNumber : ""
			this.$refs.grid.commitProxy("reload")
		},
		uploadTimer() {
			if (this.timer) {
				clearTimeout(this.timer)
				this.timer = null
				this.count++
			}
			if (this.count < this.maxCount + 1 && this.uploadStatus == 1) {
				this.timer = setTimeout(() => {
					this.uploadMonitor()
				}, this.timeout)
			}
		},
		uploadMonitor() {
			let { uploadId: id, uploadType } = this
			let data = uploadType == "user" ? { id, operate: "adduser" } : { id }
			this.$Xhr({
				url: "/orgUser/importfileMonitor",
				data,
				method: "post",
				noLoading: true
			})
				.then(res => {
					this.uploadStatus = res.status
					if (res.status == 1) {
						this.uploadTimer()
					} else {
						clearTimeout(this.timer)
						this.resultfileAdd = res.resultfileAdd
						this.totalNumber = res.totalNumber
						this.sucNumber = res.sucNumber
						this.failNumber = res.failNumber
						if (uploadType == "user") {
							this.getUser()
						} else {
							this.getOrg()
						}
					}
				})
				.catch(() => {
					this.modalShow = false
				})
		},
		uploadOrgExcel() {
			let { uploadKey } = this
			this.$Xhr({
				url: "/orgUser/uploadOrgExcel",
				data: { aliyunAddress: uploadKey },
				method: "post"
			})
				.then(res => {
					this.uploadId = res.id
					this.uploadMonitor()
					this.uploadTimer()
				})
				.catch(() => {
					this.modalShow = false
				})
		},
		uploadUserExcel() {
			let { uploadKey } = this
			this.$Xhr({
				url: "/orgUser/uploadUserExcel",
				data: { aliyunAddress: uploadKey },
				method: "post"
			})
				.then(res => {
					this.uploadId = res.id
					this.uploadMonitor()
					this.uploadTimer()
				})
				.catch(() => {
					this.modalShow = false
				})
		},
		uploadExcel(e, type) {
			this.$Xhr({
				url: "/aliyun/get?dir=homeplus",
				method: "get"
			})
				.then(config => {
					var uuid = new UUID().toString()
					let key = config.dir + uuid
					var fd = new FormData()
					fd.append("key", key)
					fd.append("x-oss-meta-fullname", e.file.name)
					fd.append("success_action_status", "200")
					fd.append("x-oss-object-acl", "public-read")
					fd.append("OSSAccessKeyId", config.accessId)
					fd.append("policy", config.policy)
					fd.append("signature", config.signature)
					fd.append("Content-disposition", `attachment;filename=${e.file.name}`)
					fd.append("file", e.file)
					if (config.host.indexOf("http:") > -1) {
						var protocol = window.location.protocol || "http:"
						var subUrl = config.host.substring(5, config.host.length)
						config.host = protocol + subUrl
					}
					this.$Xhr({
						url: config.host,
						method: "post",
						isUpload: true,
						data: fd,
						headers: { "Content-Type": "multipart/form-data" }
					}).then(() => {
						e.file.aliyunUrl = config.host + "/" + key
						this.uploadType = type
						this.uploadKey = key
						this.modalShow = true
						if (type == "org") {
							this.uploadOrgExcel()
							this.$refs.uploadOrg.clearFiles()
						} else {
							this.uploadUserExcel()
							this.$refs.uploadUser.clearFiles()
						}
					})
				})
				.catch(() => {
					this.$toast("获取签名失败")
				})
		},
		closeModal() {
			this.modalShow = false
			this.uploadStatus = 1
			this.resultfileAdd = ""
		},
		search() {
			this.$refs.grid.commitProxy("reload")
		},
		cancel() {
			this.$emit("cancel", this.data)
		},
		submit() {
			this.$emit("submit", this.data)
		}
	},
	created: function() {
		this.getOrg()
	},
	beforeDestroy: function() {
		if (this.timer) {
			clearTimeout(this.timer)
			this.timer = null
		}
	}
}
</script>
<style scoped lang="scss">
.tree-cont {
	width: 26.5rem;
	background: #ffffff;
	margin-right: 1.6rem;
}
.tree {
	width: 100%;
	border: 0.1rem solid #e5e5e5;
	height: 57.3rem;
	overflow-y: auto;
	overflow-x: hidden;
}
.grid-cont {
	flex: 1;
}
.grid {
	width: 100%;
	border: 0.1rem solid #e5e5e5;
	height: 57.3rem;
	overflow-y: auto;
	overflow-x: hidden;
}
.head {
	height: 1.9rem;
	line-height: 1.9rem;
	font-weight: bold;
	margin: 0 0.2rem 1.2rem;
}
.search-form {
	padding: 1.8rem 1.5rem 0;
	white-space: nowrap;
	height: 3.2rem;
	margin-bottom: 2.5rem;
}
.search-btn {
	width: 7.6rem;
	margin-left: 3rem;
}
.grid-cell {
	color: #666;
}
.foot {
	width: 100%;
	margin-top: 2rem;
	display: flex;
	align-content: space-between;
}
.f-1 {
	flex: 1;
}
.mg-r55 {
	margin-right: 5.5rem;
}
.import-btn {
	width: 11rem;
	padding: 0;
	height: 4rem;
	line-height: 4rem;
}
.fa-c {
	display: flex;
	align-items: center;
}
.filter-tree {
	height: calc(100% - 40px);
}
.dot {
	font-family: simsun;
	animation: dot 3s infinite step-start;
}

:root .dot {
	display: inline-block;
	width: 1.5em;
	vertical-align: bottom;
	overflow: hidden;
}

@keyframes dot {
	0% {
		width: 0;
		margin-right: 1.5em;
	}
	33% {
		width: 0.5em;
		margin-right: 1em;
	}
	66% {
		width: 1em;
		margin-right: 0.5em;
	}
	100% {
		width: 1.5em;
		margin-right: 0;
	}
}
</style>

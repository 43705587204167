<template>
	<el-form ref="data" :model="data" :rules="rules" :label-width="$isMobile ? '7rem' : '14.9rem'">
		<el-form-item label="企业名称" prop="enterprisePerson">
			<el-input v-model="data.enterprisePerson" maxlength="50" placeholder="请输入企业名称" :disabled="disabled"></el-input>
		</el-form-item>
		<el-form-item label="统一信用代码" prop="creditCode">
			<el-input v-model="data.creditCode" maxlength="20" placeholder="请输入统一信用代码" :disabled="disabled"></el-input>
		</el-form-item>
		<el-form-item label="联系地址" prop="householdAddress">
			<div class="dis-f">
				<el-form-item class="pd-r0">
					<city-select
						type="province"
						extraClass="city-select"
						:value="data.householdAddress"
						:disabled="disabled"
						placeholder="请选择省市"
						@selected="selectCity"
						class="mg-r10"
					/>
				</el-form-item>
				<el-form-item prop="address" class="pd-r0 f-1">
					<el-input v-model="data.address" class="f-1" maxlength="50" placeholder="请输入详细地址" :disabled="disabled"></el-input>
				</el-form-item>
			</div>
		</el-form-item>
		<el-form-item label="企业电话" prop="enterprisePhone">
			<el-input v-model="data.enterprisePhone" maxlength="20" placeholder="请输入企业电话" :disabled="disabled"></el-input>
		</el-form-item>
		<el-form-item label="企业邮箱" prop="email">
			<el-input v-model="data.email" maxlength="50" placeholder="请输入企业邮箱" :disabled="disabled"></el-input>
		</el-form-item>
		<el-form-item label="法人代表" prop="legalRepresentative">
			<el-input v-model="data.legalRepresentative" maxlength="20" placeholder="请输入法人代表" :disabled="disabled"></el-input>
		</el-form-item>
		<el-form-item label="法人身份证号码" prop="idCode">
			<el-input v-model="data.idCode" maxlength="20" placeholder="请输入法人身份证号码" :disabled="disabled"></el-input>
		</el-form-item>
		<el-form-item label="品牌名称" prop="brand">
			<el-input v-model="data.brand" maxlength="50" placeholder="品牌名称" :disabled="disabled"></el-input>
		</el-form-item>
		<div class="block-head">上传企业证件照</div>
		<el-form-item label="营业执照" prop="businessLicenseUrl">
			<el-checkbox-group v-model="data.businessLicenseUrl" v-show="false"></el-checkbox-group>
			<upload-pic
				key="businessLicense"
				@change="licenseChange($event, 1)"
				:disabled="disabled"
				:image-url="data.businessLicenseUrl ? this.imgPrefix(data.businessLicenseUrl, this.ossPrefix) : null"
			>
				<div class="defaultImg">
					<img :src="$staticUrl + 'images/default/license.png'" />
				</div>
			</upload-pic>
		</el-form-item>
		<el-form-item label="法人代表身份证" required>
			<div class="pics-cont">
				<el-form-item prop="idCardFrontUrl" class="pd-r0">
					<el-checkbox-group v-model="data.idCardFrontUrl" v-show="false"></el-checkbox-group>
					<upload-pic
						key="idCardFront"
						class="mg-r20"
						@change="licenseChange($event, 2)"
						:disabled="disabled"
						:image-url="data.idCardFrontUrl ? this.imgPrefix(data.idCardFrontUrl, this.ossPrefix) : null"
					>
						<div class="defaultImg">
							<img :src="$staticUrl + 'images/default/ID_front.png'" />
						</div>
					</upload-pic>
				</el-form-item>
				<el-form-item prop="idCardReverseUrl">
					<el-checkbox-group v-model="data.idCardReverseUrl" v-show="false"></el-checkbox-group>
					<upload-pic
						key="idCardReverse"
						@change="licenseChange($event, 3)"
						:disabled="disabled"
						:image-url="data.idCardReverseUrl ? this.imgPrefix(data.idCardReverseUrl, this.ossPrefix) : null"
					>
						<div class="defaultImg">
							<img :src="$staticUrl + 'images/default/ID_back.png'" />
						</div>
					</upload-pic>
				</el-form-item>
			</div>
		</el-form-item>
		<el-form-item label="开户许可证">
			<upload-pic
				key="openPermit"
				@change="licenseChange($event, 4)"
				:disabled="disabled"
				:image-url="data.openPermitUrl ? this.imgPrefix(data.openPermitUrl, this.ossPrefix) : null"
			>
				<div class="defaultImg">
					<img :src="$staticUrl + 'images/default/permit.png'" />
				</div>
			</upload-pic>
		</el-form-item>
		<div class="form-foot-buttons" :style="{ marginLeft: $isMobile ? '0' : '14.9rem' }">
			<el-button type="primary" @click="submit()">{{ submitText }}</el-button>
		</div>
	</el-form>
</template>
<script>
import { mapState } from "vuex"
import CitySelect from "@/components/CitySelect"
import UploadPic from "@/components/UploadPic"

export default {
	computed: { ...mapState(["ossPrefix"]) },
	name: "basic-info",
	data: function() {
		return {
			previewModalShow: false,
			previewImageUrl: null,
			data: {
				enterprisePerson: "",
				creditCode: "",
				householdAddress: "",
				address: "",
				enterprisePhone: "",
				email: "",
				legalRepresentative: "",
				idCode: "",
				brand: "",
				businessLicenseUrl: "",
				idCardFrontUrl: "",
				idCardReverseUrl: "",
				openPermitUrl: ""
			},
			rules: {
				enterprisePerson: [{ required: true, message: "请输入企业名称", trigger: ["blur", "change"] }],
				creditCode: [{ required: true, message: "请输入统一信用代码", trigger: ["blur", "change"] }],
				householdAddress: [{ required: true, message: "请选择省市", trigger: ["blur", "change"] }],
				address: [{ required: true, message: "请输入详细地址", trigger: ["blur", "change"] }],
				enterprisePhone: [{ required: true, message: "请输入企业电话", trigger: ["blur", "change"] }],
				email: [{ required: true, message: "请输入邮箱", trigger: ["blur", "change"] }],
				legalRepresentative: [{ required: true, message: "请输入法人代表", trigger: ["blur", "change"] }],
				idCode: [{ required: true, message: "请输入法人身份证号码", trigger: ["blur", "change"] }],
				brand: [{ required: true, message: "请输入品牌名称", trigger: ["blur", "change"] }],
				businessLicenseUrl: [{ required: true, message: "请上传营业执照", trigger: ["blur", "change"] }],
				idCardFrontUrl: [{ required: true, message: "请上传法人代表身份证头像面", trigger: ["blur", "change"] }],
				idCardReverseUrl: [{ required: true, message: "请上传法人代表身份证国徽面", trigger: ["blur", "change"] }]
			}
		}
	},
	props: {
		form: { default: {} },
		disabled: { default: false },
		submitText: { default: "提交" },
		size: { default: null },
		classNames: {}
	},
	watch: {
		form(val) {
			this.data = Object.assign(this.data, val)
		}
	},
	methods: {
		licenseChange(e, pic) {
			let url = e && e.aliyunKey ? e.aliyunKey : null
			switch (pic) {
				case 1:
					this.data.businessLicenseUrl = url
					break
				case 2:
					this.data.idCardFrontUrl = url
					break
				case 3:
					this.data.idCardReverseUrl = url
					break
				default:
					this.data.openPermitUrl = url
					break
			}
		},
		selectCity({ nodes, name }) {
			this.data.provinceId = nodes[0].value || ""
			this.data.provinceName = nodes[0].label || ""
			this.data.cityId = (nodes[1] && nodes[1].value) || ""
			this.data.cityName = (nodes[1] && nodes[1].label) || ""
			this.data.householdAddress = name
		},
		submit() {
			this.$refs.data
				.validate()
				.then(() => {
					this.$emit("submit", this.data)
				})
				.catch(() => {
					return false
				})
		}
	},
	created: function() {
		this.labelWidth = this.$isMobile ? "7rem" : "14.9rem"
	},
	components: {
		CitySelect,
		UploadPic
	}
}
</script>
<style scoped lang="scss">
.form-item {
	padding: 1.1rem 0;
	font-weight: bold;
	color: #333;
}
.block-head {
	height: 4.5rem;
	line-height: 4.5rem;
	background: #f6f8fc;
	padding: 0 2.5rem;
	font-weight: bold;
	margin: 4rem 0 2rem;
}
.w100 {
	width: 10rem;
}
.f-1 {
	flex: 1;
}
.city-select {
	width: 13rem;
	@media screen and (max-width: 1024px) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 1.5rem;
	}
}
.upload-text {
	padding-top: 3rem;
	color: #2f70f6;
}
.upload-img {
	border: none;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	width: 17.2rem;
	height: 10rem;
}
.upload-icon {
	font-weight: bold;
}
.pics-cont {
	display: flex;
	@media screen and (max-width: 1024px) {
		flex-wrap: wrap;
		justify-content: space-between;
	}
}
.defaultImg {
	padding: 1.4rem 2.8rem;
	width: 100%;
	height: 100%;
	display: flex;
	overflow: hidden;
}
.defaultImg img {
	margin: 0 auto;
	width: 100%;
}
.pd-r0 {
	padding-right: 0;
}
</style>

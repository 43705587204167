<template>
	<el-form ref="ver" :class="extraClass" :label-width="labelWidth" :rules="rules" :model="data">
		<el-form-item v-if="config.mobile" :label="config.mobile.label" :prop="config.mobile.prop">
			<el-input maxlength="11" :prefix-icon="config.mobile.icon" :placeholder="'请输入' + (config.mobile.label ? config.mobile.label : '手机号码')" v-model="data.mobile" />
		</el-form-item>
		<el-form-item v-if="config.code" :label="config.code.label" :prop="config.code.prop">
			<div class="dis-f w-p100">
				<el-input
					maxlength="4"
					:prefix-icon="config.code.icon"
					:placeholder="'请输入' + (config.code.label ? config.code.label : '右侧图形码')"
					class="f-1 mg-r10"
					v-model="data.code"
				/>
				<img class="random-img" :src="randomImgUrl" @click="refreshCode" />
			</div>
		</el-form-item>
		<el-form-item v-if="config.authCode" :label="config.authCode.label" :prop="config.authCode.prop">
			<div class="dis-f w-p100">
				<el-input
					maxlength="6"
					:prefix-icon="config.authCode.icon"
					:placeholder="'请输入' + (config.authCode.label ? config.authCode.label : '短信验证码')"
					class="f-1 mg-r10"
					v-model="data.authCode"
				/>
				<el-button class="send-btn" :class="!canClick && 'invalid'" type="cancel" @click="send">{{ sendText }}</el-button>
			</div>
		</el-form-item>
	</el-form>
</template>
<script>
export default {
	name: "verify",
	data: function() {
		return {
			data: {
				mobile: "",
				code: "",
				authCode: ""
			},
			sendText: "发送短信验证码",
			canClick: true,
			randomImgUrl: ""
		}
	},
	props: {
		extraClass: {},
		rules: {
			type: Object,
			default: () => {
				return {
					code: [{ required: true, message: "请输入图形验证码", trigger: ["blur", "change"] }],
					mobile: [{ required: true, message: "请输入手机号码", trigger: ["blur", "change"] }],
					authCode: [{ required: true, message: "请输入短信验证码", trigger: ["blur", "change"] }]
				}
			}
		},
		mobile: { default: "" },
		config: {
			default: {
				mobile: {},
				code: {},
				authCode: {}
			}
		},
		labelWidth: {},
		url: {
			default: "/userLogin/sendLoginAuthCode"
		},
		method: { default: "get" }
	},
	created() {
		this.init()
	},
	watch: {
		mobile: function() {
			if (this.data.mobile !== this.mobile) {
				this.data.mobile = this.mobile
			}
		},
		"data.mobile": function(v) {
			if (this.data.mobile !== v.trim()) {
				this.data.mobile = v.trim()
			} else {
				this.change()
			}
		},
		"data.code": function(v) {
			if (this.data.code !== v.trim()) {
				this.data.code = v.trim()
			} else {
				this.change()
			}
		},
		"data.authCode": function(v) {
			if (this.data.authCode !== v.trim()) {
				this.data.authCode = v.trim()
			} else {
				this.change()
			}
		}
	},
	mounted() {},
	methods: {
		init() {
			this.refreshCode()
		},
		refreshCode() {
			let timestamp = new Date().getTime()
			this.randomImgUrl = __host + `${this.$basePath}/randomCode?timestamp=${timestamp}`
		},
		send() {
			let { mobile, code } = this.data,
				{ config, method } = this
			if (!this.canClick) return
			if (!mobile) {
				this.$toast("请输入" + (config.mobile.label ? config.mobile.label : "手机号码"), "error")
				return
			}
			if (!code && config.code) {
				this.$toast("请输入图形验证码", "error")
				return
			}
			this.canClick = false
			this.sendText = "正在发送..."
			let params = config.code ? { mobile, code } : { mobile }
			this.$Xhr({
				url: this.url,
				method,
				params: config.code ? params : "",
				data: method == "post" ? params : ""
			})
				.then(() => {
					this.$toast("验证码已发送，请查收~")
					this.changeBtnStatus()
				})
				.catch(() => {
					this.canClick = true
					this.sendText = "发送短信验证码"
					clearInterval(this.timerId)
				})
		},
		change() {
			let data = Object.assign({}, this.data)
			this.$emit("change", data)
		},
		changeBtnStatus() {
			let count = 60
			this.canClick = false
			this.timerId = setInterval(() => {
				this.sendText = count-- + "s后重新发送"
				if (0 >= count) {
					this.canClick = true
					this.sendText = "发送短信验证码"
					clearInterval(this.timerId)
				}
			}, 1000)
		},
		validate() {
			return this.$refs.ver.validate()
		}
	}
}
</script>
<style lang="scss" scoped>
.verify-form-item {
	display: flex;
	margin-bottom: 1.8rem;
}
.f-1 {
	flex: 1;
}
.random-img {
	cursor: pointer;
	width: 12.8rem;
	height: 4rem;
	@media screen and (max-width: 1024px) {
		width: 9.8rem;
	}
}
.send-btn {
	width: 12.8rem;
	height: 4rem;
	padding: 0;
	background: #fff;
	&:active{
		background-color: #eaf1fe;
	}
	&:focus {
		background-color: #fff;
	}
	@media screen and (max-width: 1024px) {
		&:hover {
			background-color: #fff;
		}
		width: 9.8rem;
		border: none;
	}
}
.invalid {
	border: 1px solid #e5e5e5;
	color: #999999;
	@media screen and (max-width: 1024px){
		border: none;
	}
}
.invalid:hover,
.invalid:active,
.invalid:focus {
	background-color: #fff;
}
</style>
